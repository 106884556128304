#login-modal input {
    margin-bottom: 20px;
}

#login-modal {
    width: 50%;
    left: 25%;
    margin-top: 60px;
    z-index: 201 !important;
}

#login-modal-backdrop {
    z-index: 200 !important;
}

#login-modal .modal-body {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
}

#login-modal button {
    width: 100%;
}