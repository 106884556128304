.kt-wizard-v3 {
    flex-direction: column; }
    .kt-wizard-v3 .kt-wizard-v3__nav {
      border-bottom: 0px solid #eeeef4; }
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items {
        display: flex;
        align-items: flex-end;
        padding: 0 4rem;
        cursor: pointer; }
        .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          flex: 0 0 20%; }
          .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-body {
            flex: 1;
            display: flex;
            flex-direction: column;
            color: #959cb6;
            padding: 2rem 0.5rem; }
            .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-body .kt-wizard-v3__nav-label {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 0.5rem; }
              .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-body .kt-wizard-v3__nav-label span {
                font-size: 1.5rem;
                margin-right: 0.5rem; }
            .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-body .kt-wizard-v3__nav-bar {
              height: 4px;
              width: 100%;
              background-color: #dee1eb;
              position: relative; }
              .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-body .kt-wizard-v3__nav-bar:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                width: 0;
                background-color: transparent;
                transition: all 0.3s; }
          .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="current"]:after {
            color: #5d78ff; }
          .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="current"] .kt-wizard-v3__nav-body {
            color: #5d78ff; }
            .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="current"] .kt-wizard-v3__nav-body .kt-wizard-v3__nav-bar:after {
              width: 100%;
              background-color: #5d78ff; }
        @media (max-width: 768px) {
          .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items {
            flex-direction: column;
            align-items: flex-start;
            padding: 2rem 0; }
            .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item {
              flex: 0 0 100%;
              position: relative;
              width: 100%; }
              .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item:after {
                position: absolute;
                right: 2rem; }
              .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item:last-child:after {
                content: "\f105";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 2rem;
                color: #dee1eb; }
              .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-body {
                justify-content: flex-start;
                flex: 0 0 100%;
                padding: 0.5rem 2rem; } }
      .kt-wizard-v3 .kt-wizard-v3__nav.kt-wizard-v3__nav-items--clickable .kt-wizard-v3__nav-item {
        cursor: pointer; }
    .kt-wizard-v3 .kt-wizard-v3__wrapper {
      display: flex;
      justify-content: center;
      background-color: #f9fafc; }
      .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form {
        width: 60%;
        padding: 4rem 0 5rem; }
        .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content {
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          border-bottom: 1px solid #eeeef4; }
          .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__form {
            margin-top: 3rem; }
          .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__review .kt-wizard-v3__review-item {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #eeeef4; }
            .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__review .kt-wizard-v3__review-item .kt-wizard-v3__review-title {
              font-weight: 600;
              font-size: 1.1rem;
              margin-bottom: 0.7rem; }
            .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__review .kt-wizard-v3__review-item .kt-wizard-v3__review-content {
              line-height: 1.8rem; }
            .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__review .kt-wizard-v3__review-item:last-child {
              border-bottom: none;
              margin-bottom: 0;
              padding-bottom: 0; }
        .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-form__actions {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          @media (max-width: 576px) {
            .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-form__actions {
              flex-wrap: wrap;
              justify-content: center; } }
          @media (max-width: 576px) {
            .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-form__actions .btn {
              margin: 0 0.5rem 1rem; } }
          .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
            margin-right: auto; }
            @media (max-width: 576px) {
              .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
                margin-right: 0.5rem; } }
          .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
            margin: auto 0 auto auto; }
            @media (max-width: 576px) {
              .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
                margin: 0 0 1rem; } }
      @media (max-width: 768px) {
        .kt-wizard-v3 .kt-wizard-v3__wrapper {
          padding: 1rem; }
          .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form {
            width: 100%;
            padding: 2rem 1rem 4rem; } 
            .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__form{
                margin-top: 0px;
            }
        }
    .kt-wizard-v3.kt-wizard-v3--white .kt-wizard-v3__wrapper {
      background-color: #ffffff; }
    .kt-wizard-v3 [data-ktwizard-type="step-info"] {
      display: none; }
      .kt-wizard-v3 [data-ktwizard-type="step-info"][data-ktwizard-state="current"] {
        display: block; }
    .kt-wizard-v3 [data-ktwizard-type="step-content"] {
      display: none; }
      .kt-wizard-v3 [data-ktwizard-type="step-content"][data-ktwizard-state="current"] {
        display: block; }
    .kt-wizard-v3 [data-ktwizard-type="action-prev"] {
      display: none; }
    .kt-wizard-v3 [data-ktwizard-type="action-next"] {
      display: inline-block; }
    .kt-wizard-v3 [data-ktwizard-type="action-submit"] {
      display: none; }
    .kt-wizard-v3[data-ktwizard-state="first"] [data-ktwizard-type="action-prev"] {
      display: none; }
    .kt-wizard-v3[data-ktwizard-state="first"] [data-ktwizard-type="action-next"] {
      display: inline-block; }
    .kt-wizard-v3[data-ktwizard-state="first"] [data-ktwizard-type="action-submit"] {
      display: none; }
    .kt-wizard-v3[data-ktwizard-state="between"] [data-ktwizard-type="action-prev"] {
      display: inline-block; }
    .kt-wizard-v3[data-ktwizard-state="between"] [data-ktwizard-type="action-next"] {
      display: inline-block; }
    .kt-wizard-v3[data-ktwizard-state="between"] [data-ktwizard-type="action-submit"] {
      display: none; }
    .kt-wizard-v3[data-ktwizard-state="last"] [data-ktwizard-type="action-prev"] {
      display: inline-block; }
    .kt-wizard-v3[data-ktwizard-state="last"] [data-ktwizard-type="action-next"] {
      display: none; }
    .kt-wizard-v3[data-ktwizard-state="last"] [data-ktwizard-type="action-submit"] {
      display: inline-block; }
  