.client-container {
    padding-top: unset;
    height: auto;
    width: 100%;
    padding: 10px;
    text-align: left;
    margin-bottom: 10px;
}

.client-containerForMembers {
    padding-top: unset;
    height: auto;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.client-container input {
    margin-top: 10px;
}


.btn-primary,
.btn-brand {
    background-color: #1a73e9;
    display: block;
}

.btn-primary:disabled {
    background-color: #1a375c;
}

.btn-primary:disabled:hover {
    background-color: #1a375c;
}

.btn-primary:hover,
.btn-brand:hover {
    background-color: #0962d8;
}

.card-title {
    font-weight: bold;
    font-size: 1.15em;
    margin-bottom: 10px;
    display: block;
}

.col-md-4 {
    margin-bottom: 20px;
}
