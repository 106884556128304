.username-btn {
    text-align: left;
    padding: unset;
}

#kt_modal_4_2 {
    left: 25%;
    width: 50%;
    height: 80%;
}

.btn-hidden:disabled {
    border: none;
    background-color: transparent;
    cursor: default;
}

.bg-color-primary {
    background-color: #1a73e9;
    border-color: #1a73e9;
}

.bg-color-primary:hover {
    background-color: #0962d8;
    border-color: #0962d8;
}

.kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="current"] .kt-wizard-v3__nav-body .kt-wizard-v3__nav-bar:after {
    background-color: #1a73e9;
}

.kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="current"] .kt-wizard-v3__nav-body {
    color: #1a73e9;
}

.kt-wizard-v3__content.wizard-step2{
    border: 1px solid #eeeef4;
    border-bottom: none !important;
    padding-bottom: 0px !important;
    margin-bottom: 3rem !important;
    margin-top: 1rem ;
}

.kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items {
    padding: 0rem !important;
}

.kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-body {
    padding: 0.5rem 0 !important;
}

.kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content {
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    border-bottom: none !important;
}

.kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form {
    width: 100% !important;
    padding: 1rem 0 !important;
}

.kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__form {
    margin-top: 1rem;
}

.plan-portlet{
    box-shadow: none;
    border: 1.5px solid rgb(235, 232, 232);
    color: #284255 ;
    cursor: default;
}

.kt-widget24 .plan-card {
    padding: 15px 20px;
    flex: 1;
}

.kt-widget24 .kt-widget24__details .kt-widget24__info .kt-widget24__title {
    font-size: 1.3rem;
    font-weight: 500;
}

.kt-widget24 .kt-widget24__details .kt-widget24__info .kt-widget24__title:hover {
    color: #284255 ;
}

.kt-widget24 .kt-widget24__details .kt-widget24__info .kt-widget24__title > i {
    font-size: 13px;
    margin-top: 2px;
}

.kt-widget24 .kt-widget24__details .kt-widget24__info {
    font-size: 12px;
    color: #74788d;
    font-weight: 500;
}
.kt-widget24 .kt-widget24__details .kt-widget24__info > p > i {
    font-size: 12px;
    margin-right: 4px;
}

.kt-widget24 .kt-widget24__details .kt-widget24__stats {
    font-family: "Roboto", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    padding-left: 0.5rem;
}

.kt-widget24 .kt-widget24__details .kt-widget24__stats > strike {
    color: #bbbbbb;
    text-decoration-color: #f64e60;
    font-size: 1.5rem;
}
.kt-widget24 .kt-widget24__details .kt-widget24__stats > p{
    font-size: 11px;
}

.kt-widget24__plan .btn-group {
    text-align: center;
    display: inline;
    /* margin: 2.5rem auto 0 auto; */
}

.kt-widget24__plan .btn-group .btn {
    margin-right: 1px;
    background-color: rgba(93, 120, 255, 0.1);
    padding: 5px 10px;
    color: #5d78ff;
}

.kt-widget24__plan .btn-group .btn.active {
    background: #1a73e9;
    color: #ffffff;
}

@media (max-width: 768px){    
    .kt-wizard-v3 .kt-wizard-v3__wrapper {
        padding: 0rem 0.3rem;
    }

    .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items {
        flex-direction: row;
        align-items: flex-start;
    }

    #add-member-form div {
        margin-bottom: 1rem;
    }

    .kt-wizard-v3 .kt-wizard-v3__wrapper .kt-form .kt-wizard-v3__content .kt-wizard-v3__form {
        margin-top: 0px;
    }
    
    .teamTable .kt-user-card-v2__pic{
        display: none;
    }

    .teamTable .member-name-cell{
        width: 25%;
    }
    .kt-widget24.plan-card {
        padding: 10px;
        flex: 1;
    }
    .kt-widget24 .kt-widget24__details .kt-widget24__stats{
        font-size: 1.5rem;
    }
    .kt-widget24 .kt-widget24__details .kt-widget24__stats > strike {
        font-size: 1.2rem;
    }

    .kt-widget24__plan .btn-group .btn {
        min-width: 68px;
    }
    
    .btn-group > .btn:not(:first-child){
        margin-left: 0rem;
    }
    .kt-widget24__plan{
        text-align: center;
    }

}